import { Flex, Image, Text } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import checksign from "./assets/check-sign.png"
import { useNavigate } from "react-router-dom"


export default function SecurePage() {
    const [checked, setChecked] = useState(false)
    const [valid, setValid] = useState(false)
    const [data1, setData1] = useState("")
    const [data2, setData2] = useState("")
    const [err1, setErr1] = useState(false)
    const [err2, setErr2] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if(data1 === "") {
            setErr1(false)
        }
        else if(data1.length < 12) {
            setErr1(true)
        }
        else {
            setErr1(false)
        }

        if(data2 === "") {
            setErr2(false)
        }
        else if(data2 !== data1) {
            setErr2(true)
        }
        else {
            setErr2(false)
        }

        if(data1 !== "" && data2 !== "" && data1 === data2 && data1.length > 11 && checked) {
            setValid(true)
        }
        else {
            setValid(false)
        }
    }, [checked, data1, data2])

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 2 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Protect your wallet</Text>
                    <Text fontSize="14px" color="#ACACD3">Create a <Text as="span" fontWeight="700" color="#fff">password</Text> to secure and unlock HashPack. Your data is securely encrypted on this device.</Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    
                    <Flex w="100%" direction={["column", "row"]} align="flex-end" gap={["0", "16px"]}>
                        <Flex w="100%" direction="column" position="relative">
                            <Text lineHeight="26px" fontWeight="500">Password</Text>
                            <Text mb="8px" color="#ACACD3" fontSize="14px">Must contain a minimum of 12 characters</Text>
                            <Flex w='100%'>
                                <input placeholder="Password" type="password" onInput={(e) => setData1(e.target.value)} />
                            </Flex>
                            {
                                err1 &&
                                <Text color="#D37385" mt="8px" fontSize="12px" fontWeight="500" position="absolute" bottom="-25px">Password too short</Text>
                            }
                        </Flex>

                        
                        <Flex w="100%" direction="column" position="relative">
                            <Text mt={["8", "0"]} mb="6px" lineHeight="26px" fontWeight="500">Confirm password</Text>
                            <Flex w='100%'>
                                <input placeholder="Confrim Password" type="password" onInput={(e) => setData2(e.target.value)} />
                            </Flex>
                            {
                                err2 &&
                                <Text color="#D37385" mt="8px" fontSize="12px" fontWeight="500" position="absolute" bottom="-25px">Passwords do not match</Text>
                            }
                        </Flex>
                    </Flex>

                    <Flex mt={[err2 ? "36px" : "16px", (err2 || err1) ? "36px" : "16px"]} w="100%" align="flex-start">
                        <Flex cursor="pointer" w="28px" h="28px" bg="#252836" border="1px solid #2F3142" borderRadius="8px" justify="center" align="center" onClick={() => checked ? setChecked(false) : setChecked(true)}>{checked && <Image w="17px" h="auto" src={checksign} />}</Flex>

                        <Flex flex="1" ml="8px">
                            <Text fontSize="14px" color="#ACACD3" lineHeight="26px">
                                I agree to the <Text as="span" color="#FFFFFF" fontWeight="500" textDecoration="underline" cursor="pointer" onClick={() => window.open("https://terms-and-conditions", "_BLANK")}>Terms and Conditions</Text> and <Text as="span" color="#FFFFFF" fontWeight="500" textDecoration="underline" cursor="pointer" onClick={() => window.open("https://privacy-policy", "_BLANK")}>Privacy Policy</Text>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap="16px" align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>

                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor={valid ? "pointer" : "not-allowed"} borderRadius="8px" fontWeight="600" opacity={valid ? "1" : ".5"} bg="#525298" _hover={{ bg: valid && "#464685" }} transition="250ms ease-in-out" onClick={() => {
                    if(valid) {
                        const a = sessionStorage.getItem("nxtrt")
                        navigate(a === "zero" ? "/create" : a === "two" ? "/hardware-type" : "/import-type")
                    }
                }}>Next</Flex>
            </Flex>
        </Flex>
    )
}