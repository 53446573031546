import { Flex, Image, Text } from "@chakra-ui/react"
import { Svg4 } from "../utility/svgs"


export default function NetworkModal({ setIsOpen }) {
    return (
        <Flex w="100%" position="fixed" zIndex="1000" direction="column" h="100%" justify="center" align="center">
            <Flex w="100%" position="absolute" h="100%" onClick={() => setIsOpen(false)} backdropFilter="blur(2px)" bg="rgba(0,0,0,.4)"></Flex>

            <Flex w={["calc(100% - 32px)", "500px"]} borderTop="7px solid #e46464" borderRadius="16px" bg="#1F1D2B" direction="column" color="#fff" className="fade-up">
                <Flex w="100%" direction="column" padding="32px 24px 16px">
                    <Flex w="100%" justify="center" mb="5">
                        <Flex w="150px">{Svg4}</Flex>
                    </Flex>

                    <Text fontSize="14px" color="#D37385">ERR_SYNC_FAILED: (0x6f00):</Text>


                    <Text fontSize="14px" mt="3">Connection to the blockchain timed out. Ensure the node is running or check the configuration. There might be an issue with the RPC server or endpoint. Please verify the connection settings. 
                    <Text mt="2" fontSize="12px" color="#ACACD3">HashPack is in maintenance mode.</Text></Text>
                </Flex>

                <Flex w="100%" bg="#252836" borderTop="2px solid #2F3142" padding="16px" justify="center" borderBottomRadius="16px">
                    <Flex justify="center" align="center" color="#fff" padding={["8px 28px", "9px 48px"]} cursor="pointer" borderRadius="8px" fontWeight="600" bg="#C74C62" _hover={{ bg: "#b04155" }} transition="250ms ease-in-out" onClick={() => setIsOpen(false)}>Okay</Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}