import { Flex, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { Svg6, Svg8, Svg9 } from "./utility/svgs"

export default function HardwareType() {
    const navigate = useNavigate()

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 2 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Hardware Wallet Type</Text>
                    <Text fontSize="14px" color="#ACACD3">Select the type of hardware wallet you wish to use</Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 40px 32px", "60px 35% 50px 35%"]}>
                    {
                        [{name: "Ledger", icon: Svg6, sub: "Create or import a Ledger account"},{name: "D'CENT", icon: Svg8, sub: "Create or import a D'CENT account"},{name: "Citadel", icon: Svg9, sub: "Create or import a Citadel account"}].map((item, index) => (
                            <Flex cursor="pointer" w="100%" key={index} padding="14px" bg="#252836" borderRadius="8px" fontSize="14px" color="#fff" align="center" border="2px solid transparent" _hover={{ border: "2px solid #7A7AB8", bg: "#2E314B"}} mb="8px" transition="200ms ease-in-out" onClick={() => {
                                sessionStorage.setItem("hrdtp", index === 0 ? "zero" : index === 1 ? "one" : "two")
                                navigate("/hardware-connect")
                            }}>
                                <Flex mr="12px">{item.icon}</Flex>
                                <Flex flex="1" direction="column">
                                    <Text fontWeight="600">{item.name}</Text>
                                    <Text color="#ACACD3" fontSize="12px">{item.sub}</Text>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>

            <Flex align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex w="100%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>
            </Flex>
            
        </Flex>
    )
}