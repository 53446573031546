import { Flex, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

export default function CreateAccount() {
    const navigate = useNavigate()

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 2 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Create a new wallet</Text>
                    <Text fontSize="14px" color="#ACACD3">All HashPack wallets are non-custodial. <Text as="span" color="#fff" fontWeight="500" cursor="pointer" textDecoration="underline" onClick={() => window.open("https://learn-more", "_BLANK")}>Learn more</Text></Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 32px 32px", "60px 28% 32px"]} gap="16px">
                    <Flex w="100%" border="1px solid #2F3142" borderRadius="8px" padding="20px" direction="column">
                        <Text textAlign="center" mb="8px" color="#fff" fontWeight="500" fontSize="24px">Advanced creation</Text>

                        <Text lineHeight="24px" textAlign="center" color="#ACACD3" mb="16px" fontSize="14px" px="16px">Create a new software wallet, multisig wallet, or cold wallet.</Text>

                        <Flex w="100%" justify="center" align="center" color="#fff" padding="10px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#525298" _hover={{ bg: "#464685" }} transition="250ms ease-in-out" fontSize="14px" onClick={() => navigate("/create-type")}>Create a new wallet</Flex>

                        <Text mt="8px" color="#ACACD3" fontSize="12px" lineHeight="20px" textAlign="center">Recommended for users comfortable</Text>

                        <Text mt="2px" color="#ACACD3" fontSize="12px" lineHeight="20px" textAlign="center">managing their own recovery keys.</Text>
                    </Flex>
                </Flex>
            </Flex>

            <Flex align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex w="100%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>
            </Flex>
            
        </Flex>
    )
}