import { Flex, Text, Image } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import ScanningModal from "./modals/scanningModal"
import AlertModal from "./modals/alertModal"
import { useState } from "react"

export default function HardwareConnectSelect() {
    const navigate = useNavigate()
    const type = sessionStorage.getItem("hrdtp")
    const txt = type === "two" ? "Citadel" : type === "one" ? "D'CENT" : "Ledger"
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [cType, setCType] = useState("one")

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text my="2px" fontSize="24px" fontWeight="600">Connect {txt}</Text>
                    <Text fontSize="14px" color="#ACACD3">
                        Please select the method you would like to use to connect to your device. Depending on your browser and operating system you may need to try a few methods. If your device isn't showing up using any of the options, check <Text cursor="pointer" color="#fff" textDecoration="underline" onClick={() => window.open("https://about-ledger", "_BLANK")}>the official Ledger FAQ</Text> 
                        <Text><Text as="span" color="#fff" fontWeight="600">Please note</Text> - browser support for Ledger is limited, and Chrome is your best bet. The buttons will be greyed out if your browser does not support the connection method</Text>
                    </Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 32px 32px", "40px 28% 32px"]} align="center" justify="center" gap="8px">
                    {
                        ["WebHID", "WebUSB", "Bluetooth"].map((item, index) => (
                            <Flex w="100%" justify="center" align="center" color="#fff" padding="11px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#525298" _hover={{ bg: "#464685" }} transition="250ms ease-in-out" fontSize="14px" key={index} onClick={() => {
                                setCType(index === 2 ? "zero" : index === 0 ? "two" : "one")
                                setIsOpen(true)
                                setTimeout(function(){
                                    setIsOpen(false)
                                    setIsOpen2(true)
                                }, 15000)
                            }}>{item}</Flex>
                        ))
                    }
                </Flex>
            </Flex>

            <Flex align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex w="100%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Cancel</Flex>
            </Flex>

            {
                isOpen && <ScanningModal type={cType} />
            }

            {
                isOpen2 && <AlertModal setIsOpen={setIsOpen2} />
            }
            
        </Flex>
    )
}