import { Flex, Image, Text } from "@chakra-ui/react"
import { Svg4, Svg6, Svg8, Svg9 } from "../utility/svgs"
import { useNavigate } from "react-router-dom"


export default function AlertModal({ setIsOpen }) {

    const navigate = useNavigate()
    const typ = sessionStorage.getItem("hrdtp")
    const txt = typ === "two" ? "Citadel" : typ === "one" ? "D'CENT" : "Ledger"
    const icn = typ === "two" ? Svg9 : typ === "one" ? Svg8 : Svg6

    return (
        <Flex w="100%" position="fixed" zIndex="1000" direction="column" h="100%" justify="center" align="center">
            <Flex w="100%" position="absolute" h="100%" onClick={() => setIsOpen(false)} backdropFilter="blur(2px)" bg="rgba(0,0,0,.4)"></Flex>

            <Flex w={["calc(100% - 32px)", "500px"]} borderTop="7px solid #e46464" borderRadius="16px" bg="#1F1D2B" direction="column" color="#fff" className="fade-up">
                <Flex w="100%" direction="column" padding="32px 24px 16px">
                    <Flex w="100%" justify="center" mb="5">
                        <Flex w="150px">{Svg4}</Flex>
                    </Flex>

                    <Text fontSize="14px" color="#D37385">ERR_COMMUNICATION_TIMEOUT: (0x6f00):</Text>

                    <Text mt="3" fontSize="14px">Failed to sync this hardware device wallet with network. The device firmware appears to be corrupted, potentially due to an interrupted update or other technical malfunction</Text>

                    <Text mt="2" fontSize="14px">As a resolution, you can fix and update your device using your device reset seed phrase option. This process will fix, update and restore your device to its default settings. You can reconnect your device after the restore process.</Text>

                    <Text fontSize="12px" color="#ACACD3" mt="4">If this error persists you can contact your hardware device support, for more technical resolutions.</Text>
                </Flex>

                <Flex w="100%" bg="#252836" borderTop="2px solid #2F3142" padding="16px" justify="center" borderBottomRadius="16px">
                    <Flex fontSize="13px" w="100%" align="center" color="#fff" padding="8px 28px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#0a802b" _hover={{ bg: "#097327" }} transition="250ms ease-in-out" onClick={() => {
                        sessionStorage.setItem("dsptxt", "one")
                        navigate("/import-seed")
                        setIsOpen(false)
                    }}>
                        <Flex mr="3">{icn}</Flex> 
                        <Flex flex='1'>Fix and Update my {txt} with Seed Phrase</Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}