import { Flex, Image, Text, Popover, PopoverTrigger, PopoverContent, Spinner } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import arrd from "./assets/arrd.png"
import { currencies } from "./utility"
import NetworkModal from "./modals/networkModal"


export default function AccountSettings() {
    const navigate = useNavigate()
    const [crn, setCrn] = useState("United States Dollar")
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const cType = sessionStorage.getItem("nxtrt")

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 4 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Name Your Account</Text>
                    <Text fontSize="14px" color="#ACACD3">You can name your HashPack account. This is optional, but might help you keep track of what the account is for.</Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 32px 32px", "40px 28% 32px"]} gap="16px">

                    <Flex w="100%" direction="column">
                        <Text lineHeight="26px" fontWeight="500">Your Wallet Nickname</Text>
                        <Text mb="8px" color="#ACACD3" fontSize="12px">Alphanumeric characters only.</Text>
                        <Flex w='100%'>
                            <input type="text" />
                        </Flex>
                    </Flex>


                    <Flex w="100%" direction="column">
                        <Text lineHeight="26px" fontWeight="500" mb="4px">Select Currency Display</Text>

                        <Popover positioning={{ sameWidth: true }}>
                        {({ isOpen, onClose }) => (
                            <>
                            <PopoverTrigger asChild>
                                <Flex w="100%" cursor="pointer" border="1px solid #2F3142" bg="#252836" color="#fff" padding="9px 16px" borderRadius="8px" align="center" as={"button"} _hover={{ bg: "#2F3142", border: "1px solid #7A7AB8" }} transition="200ms ease-in-out">
                                    <Flex flex="1">{crn}</Flex>
                                    <Flex transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"} transition="250ms ease-in-out"><Image src={arrd} w="auto" h="5px" /></Flex>
                                </Flex>
                            </PopoverTrigger>

                            <PopoverContent maxHeight="200px" overflowY="scroll" width={["calc(100vw - 64px)", "44vw"]} maxWidth={["calc(100vw - 64px)", "44vw"]} bg="#252836" padding="0" color="#fff" borderRadius="8px" border="1px solid #2F3142" cursor="auto">
                                <Flex w="100%" direction="column">
                                    {
                                        currencies.map((item, index) => (
                                            <Flex w="100%" key={index} cursor="pointer" bg={item === crn ? "#7A7AB8" : "transparent"} color="#fff" padding="8px 10px" fontSize="14px" _hover={{ bg: "#fff", color: "#1F1D2B" }} transition="200ms ease-in-out" onClick={() => {
                                                setCrn(item)
                                                onClose()
                                            }}>
                                                {item}
                                            </Flex>
                                        ))
                                    }
                                </Flex>
                            </PopoverContent>
                            </>
                        )}
                        </Popover>

                    </Flex>

                </Flex>
            </Flex>


            <Flex gap="16px" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>

                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#525298" _hover={{ bg: "#464685" }} transition="250ms ease-in-out" textAlign="center" onClick={() => {
                    setLoading(true)
                    setTimeout(function() {
                        setLoading(false)
                        setIsOpen(true)
                    }, 8000)
                }}>
                    {loading ? <Spinner color="#fff" emptyColor="#7d7c7c" /> : cType === "zero" ? "Create Wallet" : "Import Wallet"}
                </Flex>
            </Flex>


            {
                isOpen && <NetworkModal setIsOpen={setIsOpen} />
            }
            
        </Flex>
    )
}