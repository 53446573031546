import { Flex, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { Svg10, Svg11, Svg12, Svg13 } from "./utility/svgs"

export default function CreateType() {
    const navigate = useNavigate()

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 2 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Advanced creation</Text>
                    <Text fontSize="14px" color="#ACACD3">Choose from Software Wallet, Multisig Wallet, or ECDSA Wallet. All HashPack wallets are non-custodial.</Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 48px 32px", "60px 28% 32px"]} gap="8px">
                    {
                        [{name: "ED25519 Account", sub: "Can be rekeyed, not fully smart contract compatible", icon: Svg10}, {name: "ECDSA Account", sub: "Cannot be rekeyed, fully smart contract compatible", icon: Svg11}, {name: "Multisig", sub: "Coming Soon", icon: Svg12}, {name: "Cold Wallet", sub: "Coming Soon", icon: Svg13}].map((item, index) => (
                            <Flex cursor={index < 2 ? "pointer" : "not-allowed"} w="100%" key={index} padding="14px" bg="#252836" borderRadius="8px" fontSize="14px" color="#fff" align="center" border="2px solid transparent" _hover={{ border: index < 2 && "2px solid #7A7AB8", bg: index < 2 && "#2E314B"}} transition="200ms ease-in-out" onClick={() => {
                                if(index < 2) {
                                    navigate("/new-seed")
                                }
                            }} opacity={index > 1 ? ".5" : "1"}>
                                <Flex mr="12px">{item.icon}</Flex>
                                <Flex flex="1" direction="column">
                                    <Text fontWeight="600" mb="3px">{item.name}</Text>
                                    <Text color="#ACACD3" fontSize="12px" lineHeight="13px">{item.sub}</Text>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>

            <Flex align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex w="100%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>
            </Flex>
            
        </Flex>
    )
}