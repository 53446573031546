import { Flex, Text } from "@chakra-ui/react"
import { ethers} from 'ethers'
import { useNavigate } from "react-router-dom"
import { useEffect, useState, useCallback } from "react"

export default function NewSeed() {
    const navigate = useNavigate()
    const [mn, setMn] = useState([])

    const generateMnemonic = useCallback(() => {
        const entropy = ethers.randomBytes(32)
        const newMnemonic = ethers.Mnemonic.fromEntropy(entropy).phrase
        setMn(newMnemonic.split(/\s+/))
      }, [])

    useEffect(() => {
        generateMnemonic()
    }, [])

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 3 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Save Your Seed Phrase</Text>
                    
                    <Text fontSize="14px" color="#ACACD3">A Seed Phrase allows you to recover your account. <Text as="span" color="#fff" fontWeight="500">Write down the 24-Word Phrase below. Keep it in a safe place.</Text></Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 32px 32px", "40px 14% 32px"]} gap="16px" display="grid" gridTemplateColumns={["1fr 1fr", "1fr 1fr 1fr"]} gridTemplateRows="auto">
                    {
                        mn.map((item, index) => (
                            <Flex key={index} align="center" mb="5px">
                                <Flex bg="#2E314B" color="#CDCDE4" w="20px" h="20px" justify="center" align="center" borderRadius="100%" fontSize="10px" fontWeight="600" mr="8px">{index + 1}</Flex>
                                <Flex flex="1" fontWeight="500" fontSize="14px">{item}</Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>

            <Flex gap="16px" align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>

                <Flex flex="1 1 50%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#525298" _hover={{ bg: "#464685" }} transition="250ms ease-in-out" onClick={() => navigate("/account-settings")}>Next</Flex>
            </Flex>
            
        </Flex>
    )
}