import './App.css';
import { ChakraProvider, Flex } from "@chakra-ui/react";
import Landing from './landing';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import SecurePage from './secure';
import ImportType from './import-type';
import ImportSeed from './import-seed';
import AccountSettings from './account-settings';
import ImportKey from './import-key';
import HardwareType from './hardware-type';
import HardwareConnect from './hardware-connect';
import HardwareConnectSelect from './hardware-connect-select';
import CreateAccount from './create';
import CreateType from './create-type';
import NewSeed from './new-seed';


export const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);
  return children
}


function App() {

  return (
    <ChakraProvider>
      <Flex w="100%" direction="column" bg="#1F1D2B" color="#fff" fontSize="16px">
        <BrowserRouter>
          <Wrapper>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/setup-credentials" element={<SecurePage />} />
              <Route path="/import-type" element={<ImportType />} />
              <Route path="/import-seed" element={<ImportSeed />} />
              <Route path="/import-key" element={<ImportKey />} />
              <Route path="/hardware-type" element={<HardwareType />} />
              <Route path="/hardware-connect" element={<HardwareConnect />} />
              <Route path="/hardware-connect-select" element={<HardwareConnectSelect />} />
              <Route path="/account-settings" element={<AccountSettings />} />
              <Route path="/create" element={<CreateAccount />} />
              <Route path="/create-type" element={<CreateType />} />
              <Route path="/new-seed" element={<NewSeed />} />
            </Routes>
          </Wrapper>
        </BrowserRouter>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
