import { Flex, Image, Text } from "@chakra-ui/react"
import { Svg1, Svg2 } from "./utility/svgs"
import { useEffect, useState } from "react"
import ldg from "./assets/ldg.svg"
import { useNavigate } from "react-router-dom"


export default function Landing() {
    const navigate = useNavigate()

    const words = ['Hello, you.', 'Your web3 companion', 'Unpack the wonders of Web3', 'Prepare for a journey', 'Retail pays the bills', 'Lets get packalackin', 'The last wallet you will ever need', 'Hash on that graph', 'Your wallet, your future']
    
    const [index, setIndex] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
          setIndex(prevIndex => (prevIndex + 1) % words.length);
        }, 3100)
        return () => clearInterval(interval)
    }, [])

    return (
        <Flex w="100%" direction="column" minH="100vh" py="32px" px="10px" color="#fff" className="pageLoadEffect" align="center">
            <Flex flex="1" w={["100%", "400px"]} justify="center" align="center" direction="column" pt={["0px", "32px"]}>
                <Flex mb="16px">{Svg1}</Flex>
                <Flex mb="10px">{Svg2}</Flex>

                <Flex w="100%" direction="column" justify="center" align="center" px="5%">
                    <div className="carousel-container">
                        <div className="carousel-slide">
                            <span className="word">{words[index]}</span>
                        </div>
                    </div>
                </Flex>

                <Flex w="100%" direction="column" mt="28px" gap="16px">
                    <Flex w="100%" justify="center" align="center" color="#fff" padding="11px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#525298" _hover={{ bg: "#464685" }} transition="250ms ease-in-out" onClick={() => {
                        sessionStorage.setItem("nxtrt", "zero")
                        navigate("/setup-credentials")
                    }}>Create a new wallet</Flex>

                    <Flex w="100%" justify="center" align="center" color="#fff" padding="11px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => {
                        sessionStorage.setItem("nxtrt", "one")
                        navigate("/setup-credentials")
                    }}>I already have a wallet</Flex>

                    <Flex w="100%" align="center" justify="space-between">
                        <Flex flex="1" h="1px" bg="#2F3142"></Flex>
                        <Text mx="12px" fontSize="14px" color="#ACACD3">or</Text>
                        <Flex flex="1" h="1px" bg="#2F3142"></Flex>
                    </Flex>

                    <Flex w="100%" justify="center" align="center" color="#fff" padding="11px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#2F3142" _hover={{ bg: "#242533" }} transition="250ms ease-in-out" onClick={() => {
                        sessionStorage.setItem("nxtrt", "two")
                        navigate("/setup-credentials")
                    }}>Connect Hardware Wallet <Image src={ldg} ml="10px" w="20px" h="24px" /> </Flex>
                </Flex>
            </Flex>

            <Flex mt="16px" justify="center" opacity=".2" fontStyle="italic">v11.0.2</Flex>
        </Flex>
    )
}