import { Flex, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

export default function ImportType() {
    const navigate = useNavigate()

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 2 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Import existing wallet</Text>
                    <Text fontSize="14px" color="#ACACD3">Import your account using a secret recovery phrase or private key.</Text>
                </Flex>

                <Flex w="100%" justify="center" padding={["40px 32px 32px", "80px 12% 32px"]}>
                    <Flex w={["100%", "536px"]} direction="column" gap="16px">
                        <Flex w="100%" justify="center" align="center" color="#fff" padding="11px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#525298" _hover={{ bg: "#464685" }} transition="250ms ease-in-out" fontSize="14px" onClick={() => {
                            sessionStorage.setItem("dsptxt", "zero")
                            navigate("/import-seed")
                        }}>Import recovery phrase</Flex>

                        <Flex w="100%" align="center" justify="space-between">
                            <Flex flex="1" h="1px" bg="#2F3142"></Flex>
                            <Text mx="12px" fontSize="14px" color="#ACACD3">or</Text>
                            <Flex flex="1" h="1px" bg="#2F3142"></Flex>
                        </Flex>

                        <Flex w="100%" justify="center" align="center" color="#fff" padding="11px" cursor="pointer" borderRadius="8px" fontWeight="600" bg="#525298" _hover={{ bg: "#464685" }} transition="250ms ease-in-out" fontSize="14px" onClick={() => navigate("/import-key")}>Import private key</Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex w="100%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>
            </Flex>
            
        </Flex>
    )
}