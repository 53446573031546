import { ethers } from "ethers";

export function validatePhrase(seedPhrase, deg) {
    const isValidPhrase = ethers.Mnemonic.isValidMnemonic(seedPhrase)
    const wordCount = seedPhrase.trim().split(/\s+/).length;
    const validWordCounts = [deg];
    const isWordCountValid = validWordCounts.includes(wordCount);

    return isValidPhrase && isWordCountValid;
}


export function validateKey(key) {
    const hexRegex = /^0x[a-fA-F0-9]{64,96}$/;
    const shortHexRegex = /^[a-fA-F0-9]{64,96}$/;
    return hexRegex.test(key) || shortHexRegex.test(key);
}


export const currencies = [
    'United States Dollar', 'Albanian Lek', 'Algerian Dinar', 'Argentine Peso', 'Armenian Dram', 
    'Australian Dollar', 'Azerbaijani Manat', 'Bahamian Dollar', 'Bahraini Dinar', 'Bangladeshi Taka', 
    'Barbadian Dollar', 'Belarusian Ruble', 'Belize Dollar', 'Bermudian Dollar', 'Bhutanese Ngultrum', 
    'Bolivian Boliviano', 'Bosnia and Herzegovina Convertible Mark', 'Botswana Pula', 'Brazilian Real', 
    'British Pound Sterling', 'Brunei Dollar', 'Bulgarian Lev', 'Burundian Franc', 'Cambodian Riel', 
    'Canadian Dollar', 'Cape Verdean Escudo', 'Cayman Islands Dollar', 'Central African CFA Franc', 
    'Chilean Peso', 'Chinese Yuan', 'Colombian Peso', 'Comorian Franc', 'Congolese Franc', 
    'Costa Rican Colón', 'Croatian Kuna', 'Cuban Peso', 'Czech Koruna', 'Danish Krone', 'Djiboutian Franc', 
    'Dominican Peso', 'Egyptian Pound', 'Ethiopian Birr', 'Euro', 'Fijian Dollar', 'Gambian Dalasi', 
    'Georgian Lari', 'Ghanaian Cedi', 'Gibraltar Pound', 'Guatemalan Quetzal', 'Guinean Franc', 
    'Guyanese Dollar', 'Haitian Gourde', 'Honduran Lempira', 'Hong Kong Dollar', 'Hungarian Forint', 
    'Icelandic Krona', 'Indian Rupee', 'Indonesian Rupiah', 'Iranian Rial', 'Iraqi Dinar', 'Israeli New Shekel', 
    'Jamaican Dollar', 'Japanese Yen', 'Jordanian Dinar', 'Kazakhstani Tenge', 'Kenyan Shilling', 
    'Kuwaiti Dinar', 'Kyrgyzstani Som', 'Laotian Kip', 'Latvian Lats', 'Lebanese Pound', 'Lesotho Loti', 
    'Liberian Dollar', 'Libyan Dinar', 'Lithuanian Litas', 'Macanese Pataca', 'Malagasy Ariary', 
    'Malawian Kwacha', 'Malaysian Ringgit', 'Maldivian Rufiyaa', 'Maltese Lira', 'Mauritian Rupee', 
    'Mexican Peso', 'Moldovan Leu', 'Mongolian Tugrik', 'Moroccan Dirham', 'Mozambican Metical', 
    'Myanmar Kyat', 'Namibian Dollar', 'Nepalese Rupee', 'Netherlands Antillean Guilder', 
    'New Zealand Dollar', 'Nicaraguan Córdoba', 'Nigerian Naira', 'North Korean Won', 'Norwegian Krone', 
    'Omani Rial', 'Pakistani Rupee', 'Palauan Dollar', 'Panamanian Balboa', 'Papua New Guinean Kina', 
    'Paraguayan Guarani', 'Peruvian Nuevo Sol', 'Philippine Peso', 'Polish Zloty', 'Qatari Rial', 
    'Romanian Leu', 'Russian Ruble', 'Rwandan Franc', 'Saint Helena Pound', 'Samoan Tala', 'Sao Tome and Principe Dobra', 
    'Saudi Riyal', 'Serbian Dinar', 'Seychellois Rupee', 'Singapore Dollar', 'Solomon Islands Dollar', 
    'Somali Shilling', 'South African Rand', 'South Korean Won', 'Sri Lankan Rupee', 'Sudanese Pound', 
    'Surinamese Dollar', 'Swedish Krona', 'Swiss Franc', 'Syrian Pound', 'São Tomé and Príncipe Dobra', 
    'Tajikistani Somoni', 'Tanzanian Shilling', 'Thai Baht', 'Tongan Paʻanga', 'Trinidad and Tobago Dollar', 
    'Tunisian Dinar', 'Turkish Lira', 'Turkmenistani Manat', 'Ugandan Shilling', 'Ukrainian Hryvnia', 
    'United Arab Emirates Dirham', 'Uruguayan Peso', 'Uzbekistani Som', 'Vanuatu Vatu', 'Venezuelan Bolívar', 
    'Vietnamese đồng', 'Western Sahara Dirham', 'Yemeni Rial', 'Zambian Kwacha', 'Zimbabwean Dollar'
]
