import { Flex, Image, Text } from "@chakra-ui/react"
import { Svg4 } from "../utility/svgs"
import cn1 from "../assets/cn1.png"
import cn2 from "../assets/cn2.png"
import cn3 from "../assets/cn3.png"


export default function ScanningModal({ type }) {
    const typ = sessionStorage.getItem("hrdtp")
    const txt = typ === "two" ? "Citadel" : typ === "one" ? "D'CENT" : "Ledger"

    return (
        <Flex w="100%" position="fixed" zIndex="1000" direction="column" h="100%" justify="center" align="center">
            <Flex w="100%" position="absolute" h="100%" backdropFilter="blur(2px)" bg="rgba(0,0,0,.6)"></Flex>

            <Flex w={["calc(100% - 32px)", "500px"]} borderTop="7px solid #525298" borderRadius="16px" bg="#1F1D2B" direction="column" color="#fff" className="fade-up">
                <Flex w="100%" direction="column" padding="32px 16px">
                    <Flex w="100%" justify="center" mb="2">
                        <Flex w="150px">{Svg4}</Flex>
                    </Flex>
                    <Text textAlign="center" fontSize="12px" color="#ACACD3">Wants to {type === "zero" ? "pair" : type === "two" ? "connect to your HID device" : "connect to your USB device"}</Text>

                    <Flex w="100%" justify="center" my="60px" position="relative" direction="column" align="center">
                        <Flex position="absolute" animation="spin 1.5s linear infinite" w="150px" h="150px" border="5px solid transparent" borderTop="5px solid #525298" borderRadius="100%"></Flex>
                        <Flex position="relative" z="1">
                            <Image h="80px" w="auto" src={type === "zero" ? cn1 : type === "two" ? cn3 : cn2} />
                        </Flex>
                    </Flex>

                    <Text textAlign="center" fontSize="14px">Scanning for a compatible {txt} {type === "zero" ? "bluetooth" : type === "two" ? " HID" : "USB"} device ...</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}