import { Flex, Text, Image } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import cnct from "./assets/cnct.svg"

export default function HardwareConnect() {
    const navigate = useNavigate()
    const type = sessionStorage.getItem("hrdtp")
    const txt = type === "two" ? "Citadel" : type === "one" ? "D'CENT" : "Ledger"

    return (
        <Flex w="100%" minH="100vh" direction="column" className="pageLoadEffect">
            <Flex flex="1" direction="column">
                <Flex w="100%" direction="column" bg="#252836" borderBottom="2px solid #2F3142" padding={["40px 32px 32px", "40px 14% 32px"]}>
                    <Text color="#ACACD3" fontSize="12px">Step 2 of 4</Text>
                    <Text my="2px" fontSize="24px" fontWeight="600">Import using {txt}</Text>
                    <Text fontSize="14px" color="#ACACD3">Import your <Text color="#fff" as="span">existing Ledger account</Text>. Need help? <Text color="#fff" as="span" cursor="pointer" textDecoration="underline" onClick={() => window.open("https://guide", "_BLANK")}>Read our guide</Text></Text>
                </Flex>

                <Flex w="100%" direction="column" padding={["40px 32px 32px", "40px 28% 32px"]} align="center" justify="center">
                    <Image src={cnct} w="120px" mb="24px" />

                    <Text textAlign="center" fontSize="20px" fontWeight="600">Connect your {txt}</Text>

                    <Text textAlign="center" fontSize="14px" lineHeight="24px" mb="24px" color="#ACACD3">Make sure your {txt} is unlocked, updated, and the Hedera app is open.</Text>

                    <Flex w="100%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" fontSize="14px" onClick={() => navigate("/hardware-connect-select")}>Connect {txt}</Flex>
                </Flex>
            </Flex>

            <Flex align="center" bg="#252836" borderTop="2px solid #2F3142" padding={["16px 32px", "16px 14%"]} fontSize="14px">
                <Flex w="100%" justify="center" align="center" color="#fff" padding="8px 24px" cursor="pointer" borderRadius="8px" fontWeight="600" border="1px solid #525298" onClick={() => navigate(-1)}>Back</Flex>
            </Flex>
            
        </Flex>
    )
}